import React, { useState, useEffect } from 'react';
import productImage from './apnea.png';
import productImage2 from './cycle-breathing.png';
import appstore from './app-store.svg';
import envelope from './envelope.fill.svg';
import apple from './apple.svg';
import './App.css';

function App() {
  const [imageSrc, setImageSrc] = useState(productImage);
  const [key, setKey] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setImageSrc(currentSrc => (currentSrc === productImage ? productImage2 : productImage));
      setKey(prevKey => prevKey + 1);
    }, 7000);

    return () => clearInterval(interval);
  }, []);

  function open(url) {
    window.open(url, '_blank');
  }

  return (
    <div className='container'>
      <div className='inner'>
        <header>
          <img src={envelope} className="icon" alt="Contact" onClick={
            () => open('mailto:emil@successfulerror.com')
          } />
          <img src={apple} alt="Store" onClick={
            () => open('https://apps.apple.com/app/1668046328')
          } />
        </header>
        <div className='main'>
          <div className="headlines">
            <div className='headline'>
            Reduce stress and anxiety in matter of seconds
            </div>
            <div className='description'>
            For moments when you need to calm the nerves, improve focus and reduce stress. Whether it’s before an important meeting, before going to bed or if you just have a stressful workday. Hunchi help you manage stress, improve concentration and enhance your overall well-being.
            <br></br>
            <br></br>
            Through science based cycle breathing techniques simple press start, begin breathing and get immediate results in lowered anxiety and stress, always accessible, right on your wrist.
            </div>
            <div>
              <img className='app-store' src={appstore} alt="App Store" onClick={
                () => open('https://apps.apple.com/se/app/hunchi/id1668046328')
              } />
            </div>
          </div>
          <div className='image'>
            <div>
            <img key={key} src={imageSrc} className="product-image fade-in" alt="Product" />
            </div>
            <div className='pricing'>
              Free download for a limited period.
            </div>
            <div className='pricing-subtitle'>

            </div>
          </div>
          <div className='testimonials'>
            <div className='testimonial-a'>
              <span className='quote-a'>"Not only does it make my breath holding more enjoyable, but also helps me gain insights into how much further I can push myself."</span>
              <br></br>
              <br></br>
              <span className='quote-subtitle'>Alexander Nilsson</span>
              <br></br>
              <span className='quote-subtitle-thin'> Swedish freediving record holder</span>
            </div>
            {/* <div className='testimonial-a'>
              <span className='quote-b'>“Increased my PB with over 1 minute first week”</span><br></br>
              <span className='quote-subtitle'>Alexander Nilsson</span>
              <span className='quote-subtitle-thin'> Swedish record holder</span>
            </div>
            <div className='testimonial-a'>
              <span className='quote-c'>“Best app of the year!”</span><br></br>
              <span className='quote-subtitle'>Alexander Nilsson</span>
              <span className='quote-subtitle-thin'> Swedish record holder</span>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;